<template>
  <div class="kpi-box">
    <h2 class="ellipsis text-center">{{ title }}</h2>
    <q-separator class="q-my-sm"/>
    <div class="value-container text-center ellipsis">
      <div :style="`opacity: ${loading ? '1' : '0'}`"><q-spinner-dots class="sentiment-chart-loading" /></div>
      <div :style="`opacity: ${loading ? '0' : '1'}`">{{ data ? value : '-' }}</div>
    </div>
  </div>
</template>
<script lang="ts">
import type { DashboardKpi, DashboardTotals } from '@/types';
import { type PropType, defineComponent } from 'vue';
import HySentimentMixin from '@/mixins/Sentiment.vue';

export default defineComponent({
  mixins: [HySentimentMixin],
  props: {
    kpi: {
      type: String as PropType<DashboardKpi>,
      required: true
    },
    data: {
      type: [Object, null] as PropType<DashboardTotals | null>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  computed: {
    title(){
      return this.$t(`dashboard.${this.kpi}`)
    },
    value(): string{

      if (!this.data) {
        return ''
      }
      
      switch (this.kpi) {
        case 'lastReviewDate':
          return this.data.responseRate !== null ? this.$d(new Date(this.data.lastReviewDate as string), 'long') : '-'
        case 'reviewCount':
          return this.data.responseRate !== null ? this.$n(this.data.reviewCount as number, 'natural') : '-'
        case 'sentimentAvg':
          return this.data.responseRate !== null ? this.resolveSentiment(this.data.sentimentAvg as number) : '-'
        case 'responseRate':
          return this.data.responseRate !== null ? this.$n(this.data.responseRate as number, 'percent') : '-'
      }
    }
  }
})
</script>
<style lang="sass" scoped>
@import '@/styles/variables.sass'
.kpi-box    
  h2 
    margin: 0 
  .value-container
    position: relative
    height: 2rem
    div
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 2rem
      transition: opacity 0.5s ease  
</style>
