import { defineStore } from 'pinia'
import type { Review, RootState, ToastMessage } from '@/types'

export const useRootStore = defineStore('root', {
  state(): RootState {
    
    return {
      preLoginPath: '/',
      toastMessages: [],
      loading: [],
      activeReview: null,
    }
  },
  actions: {
    isLoading(key: string | null = null): boolean {
      if (key) {
        return this.loading.includes(key)
      } else {
        return this.loading.length > 0
      }
    },
    setLoading(key: string, isLoading: boolean) {
      if (isLoading) {
        this.loading.push(key)
      } else {

        // delay the removal of the key from the loading array to avoid promises to be resolved after 
        // the loading indicator has been removed 
        window.setTimeout(
          () => this.loading = this.loading.filter((e) => e !== key),
          100
        )
      }
    },
    addToastMessage(value: ToastMessage) {
      this.toastMessages.push(value)
    },
    popToastMessage() {
      return this.toastMessages.shift()
    },
    setActiveReview(review: Review | null) {
      this.activeReview = review
    }
  },
})
