<template>
  <div class="text-center">
    <filter-field type="state" v-model="state" @update:model-value="emitChange()"/> <span v-if="isMultiTenant()">{{ $t('filter.snippet2') }}  <filter-field type="tenant" v-model="tenant"  @update:model-value="emitChange()"/></span> 
    {{ range.type === 'predefined' ? $t(`filter.snippet3a`) : $t(`filter.snippet3b`) }}
    <filter-field type="range" v-model="range"  @update:model-value="emitChange()"/>, {{ $t('filter.snippet4') }}
    <filter-field type="platform" v-model="platform"  @update:model-value="emitChange()"/> {{ $t('filter.snippet5') }}
    <filter-field type="sentiment" v-model="sentiment"  @update:model-value="emitChange()"/> {{ $t('filter.snippet6') }}.
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import FilterField from './Field.vue';
import { useAuthStore } from '@/stores/auth';
import { Filter } from '@/types';

export default defineComponent({
  components: {
    FilterField
  },
  emits: ['change'],
  data(): Filter {
    return {
      range: this.getInitialValue('range', this.$route.params.range),
      platform: this.getInitialValue('platform', this.$route.params.platform),
      state: this.getInitialValue('state'),
      sentiment: this.getInitialValue('sentiment'),
      tenant: this.getInitialValue('tenant'),
    } 
  },
  methods: {
    emitChange(addToStore = true) {

      if (addToStore) {
        sessionStorage.setItem('hyoban.range', JSON.stringify(this.range))
        sessionStorage.setItem('hyoban.platform', this.platform)
        sessionStorage.setItem('hyoban.state', this.state)
        sessionStorage.setItem('hyoban.sentiment', this.sentiment)
        sessionStorage.setItem('hyoban.tenant', this.tenant)
      }

      this.$emit('change', {
        range: this.range,
        platform: this.platform,
        state: this.state,
        sentiment: this.sentiment,
        tenant: this.tenant,
      })
    },
    isMultiTenant() {
      return useAuthStore().getTenants().length > 1
    },
    getInitialValue(filter: string, overrideValue?: string) {
      

      // override platform value for drilldown
      if (filter === 'platform' && overrideValue && overrideValue !== '-') {
        return overrideValue as string
      }

      // override range value for drilldown
      if (filter === 'range' && overrideValue && overrideValue !== '-') {

        if (/^(\d+)_days$/.test(overrideValue)) {
          return { type: 'predefined', value: overrideValue as string }
        } else {
          return { type: 'custom', value: [new Date(overrideValue), new Date(overrideValue)] }
        }

      }

      // get value from session storage
      const value = sessionStorage.getItem(`hyoban.${filter}`)

      if (value) {
        return filter === 'range' ? JSON.parse(value) : value
      }

      // default values
      if (filter === 'range') {
        return { type: 'predefined', value: '7_days' as string }
      }

      return '*'
    }
  },
  mounted() {
    this.emitChange(false)
  }
})
</script>