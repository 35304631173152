<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $t("app.dashboard") }}</h1>
    <div class="row q-mb-xl">
      <div class="col-12">
        <hy-filter @change="fetch($event)" />
      </div>
    </div>
    <div class="row justify-center q-col-gutter-xl">
      <!-- BEGIN KpiBoxes -->
      <div v-for="box in boxes" class="col-12 col-sm-6 col-md-3">
        <hy-kpi-box :kpi="box" :data="data ? data.totals : null" :loading="loading"/>
      </div>
      <!-- END KpiBoxes -->
      <!-- BEGIN Charts -->
      <div class="col-12 col-lg-3 gt-md">
        <hy-distribution-chart :data="data ? data.distribution : null" :loading="loading" />
      </div>
      <div class="col-12 col-lg-9 gt-xs">
        <hy-development-chart :data="data ? data.development : null" :loading="loading" />
      </div>
      <!-- END Charts -->
      <!-- BEGIN Platforms -->
      <div class="col-12">
        <h2 class="text-center">{{ $t('app.platforms') }}</h2>
        <p class="text-center">
          {{ $t('dashboard.platformDescription') }}
          <span v-if="data?.platforms.length === 0"><br>{{ $t('app.noData') }}</span>
        </p>

        <div style="min-height: 183px;position: relative; ">
          <q-inner-loading :showing="loading" />
          <div class="row q-col-gutter-xl justify-center" style="margin-top: 0px">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="p in data?.platforms">
              <hy-platform-box :data="p" :loading="loading" @click="$router.push({ name: 'interactions', params: { platform: p.platform } })"/>
            </div>
          </div>
        </div>
      </div>
      
      <!-- END Platforms -->
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { DashboardResponse, DashboardKpi, Filter } from "@/types";
import { useDataStore } from "@/stores/data";
import HyFilter from "@/components/Filter/Form.vue";
import HyKpiBox from '../components/Dashboard/KpiBox.vue'
import HyPlatformBox from '../components/Dashboard/PlatformBox.vue'
import HyDevelopmentChart from '../components/Dashboard/DevelopmentChart.vue'
import HyDistributionChart from '../components/Dashboard/DistributionChart.vue'

export default defineComponent({
  components: {
    HyFilter,
    HyKpiBox,
    HyPlatformBox,
    HyDevelopmentChart,
    HyDistributionChart
  },
  data() {
    return {
      dataStore: useDataStore(),
      boxes: ['reviewCount', 'sentimentAvg', 'responseRate', 'lastReviewDate'] as DashboardKpi[],
      data: null as DashboardResponse | null,
      loading: true
    };
  },
  methods: {
    async fetch(filter: Filter) {
      this.loading = true
      try {
        this.data = await this.dataStore.fetchDashboardData(filter);
      } catch (e) {
        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("app.error"),
          message: this.$t("app.errorServer"),
        })
        console.error(e);
      } finally {
        this.loading = false
      }
    },
  },
});
</script>