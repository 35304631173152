<template>
  <q-item class="review-item" clickable @click="$emit('set-review', review)" :class="active ? 'active text-hy-primary' : 'text-hy-default'">
    <template v-if="review">
      <q-item-section avatar class="items-center">
        <img :src="platformIcon" alt="" >
          <q-tooltip :delay="500" anchor="top middle" self="center middle">{{ review.platformName.charAt(0).toUpperCase() + review.platformName.slice(1) }}</q-tooltip>
      </img>
      </q-item-section>
      <q-item-section>
        <q-item-label caption class="ellipsis">
            <span :class="active ? 'text-hy-primary' : ''">{{ !small ? `${tenantName}, ` : ''}}{{ $d(new Date(review.datePublished), 'short') }}</span>
        </q-item-label>
        <q-item-label>
          <hy-rating :rating="review.rating" :platform="review.platformName" :active="active"/>
        </q-item-label>
        <!-- <q-item-label caption>
            {{ review.author.name }}, {{ $d(review.datePublished, 'short') }}
        </q-item-label> -->
      </q-item-section>
      <q-item-section avatar >
        <span v-if="cannotRespond">
          <svg :style="active ? 'opacity: 1' : 'opacity: .5'" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2M7 13h10v-2H7"/></svg>
          <q-tooltip :delay="500" anchor="top middle" self="center middle">{{ $t('interactions.cannotRespond') }}</q-tooltip>
        </span>
        <q-icon v-else :name="hasResponse ? 'o_check_circle' : 'o_circle'" >
          <q-tooltip :delay="500" anchor="top middle" self="center middle">{{ $t(`interactions.${hasResponse ? 'responded' : 'open'}`) }}</q-tooltip>
        </q-icon>
      </q-item-section>
    </template>
  </q-item>
</template>
<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type { Review } from '@/types';
import { useAuthStore } from '@/stores/auth';
import HyRating from '@/components/Interactions/Rating.vue'

export default defineComponent({
  components: {
    HyRating
  },
  props: {
    review: {
      type: [Object, null] as PropType<Review | null>,
      required: true
    },
    vClosePopup: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    active: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    clickable: {
      type: Boolean as PropType<boolean>,
      default: true
    } ,
    small: {
      type: Boolean as PropType<boolean>,
      default: false
    } 
  },
  computed: {
    cannotRespond() {
      return (this.review?.platformName === 'booking-readonly' && this.review?.message === null)
    },
    hasResponse() {
      return this.review?.responses.some((r) => r.state === 'hyoban-auto-published' || 
        r.state === 'hyoban-manual-published' || 
        r.state === 'external-published')
    },
    platformIcon() {
      if (this.active) {
        return `/images/platform/${this.review?.platformName.replace('-readonly', '')}-color--${this.$q.dark.isActive ? 'dark' : 'light'}.png`   
      }
      return `/images/platform/${this.review?.platformName.replace('-readonly', '')}-mono--${this.$q.dark.isActive ? 'dark' : 'light'}.png`
    },
    tenantName() {

      let name = '-';
      
      const tenant = useAuthStore().getTenants().filter(t => t.id === this.review?.tenantId)[0]
      
      if (tenant) {
        name = tenant.name
      }

      if (name.length > 20) {
        return name.substring(0, 15) + '...'
      }

      return name
    }
  }
})
</script>
<style lang="sass" scoped>
@import '@/styles/variables.sass'
.review-item
  .q-item__section--avatar
    img
      max-height: 30px
      max-width: 30px
      opacity: 0.25
    i.q-icon  
      opacity: 0.5
.review-item.active
  .q-item__section--avatar
    img,      
    i.q-icon  
      opacity: 1
      
</style>