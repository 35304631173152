<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $t("adHoc.long") }}</h1>
    <div v-if="authStore.getTenants().length > 1" class="row q-mb-xl">
      <div class="col-12 text-center">
        {{ $t('adHoc.tenant') }} <tenant-filter @change="tenantId = $event" />{{ $t('adHoc.tenant2') }}.
      </div>
    </div>
    <p class="text-center">{{ $t('adHoc.description') }}</p>
    <div class="row q-col-gutter-lg">
      <div class="col-12 col-lg-10 offset-lg-1 q-mt-xl">
        <div>
          <q-input filled v-model="message" type="textarea" :placeholder="$t('adHoc.placeholder')" color="hy-primary"
            style="font-size:16px" :disable="loading" />
        </div>
        <div class="text-center q-mt-md">
          <q-btn color="hy-primary" :label="$t('app.generateResponse')" @click="generate()"
            :disable="loading || !message" style="position:relative;">
            <q-inner-loading color="white" :showing="loading"
              size="1.75rem" />
            <q-tooltip v-if="!message" :delay="500" anchor="top middle" self="bottom middle">{{ $t('adHoc.missing') }}</q-tooltip>
          </q-btn>
        </div>
      </div>
      <div class="col-12 col-lg-10 offset-lg-1" v-if="interactions.length">
        <q-timeline v-for="(items, index) in interactions">
          <!-- <q-timeline-entry heading></q-timeline-entry> -->
          <q-timeline-entry v-for="item in items" :subtitle="$d(item.date, 'long')"
            :icon="item.type === 'review' ? 'star' : 'reply'" color="hy-primary">
            <template v-slot:title>
              <span class="text-hy-default">{{ item.type === 'review' ? $t('app.review') : $t('app.response') }}</span>
              <q-btn v-if="item.type === 'review'" flat class="q-ml-md" round size="sm" color="hy-primary" icon="delete"
                @click="remove(index)">
                <q-tooltip v-if="!message" :delay="500" anchor="top middle" self="bottom middle">{{ $t('adHoc.remove') }}</q-tooltip>
              </q-btn>
            </template>
            <div v-html="item.message" style="white-space: pre-line;" class="text-hy-default"></div>

          </q-timeline-entry>
        </q-timeline>
      </div>
    </div>
  </section>
  
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TenantFilter from '@/components/Filter/TenantForm.vue';
import { useDataStore } from '@/stores/data';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  components: {
    TenantFilter
  },
  data() {
    return {
      authStore: useAuthStore(),
      tenantId: useAuthStore().getTenants()[0].id,
      message: '',
      interactions: [] as any[],
      dataStore: useDataStore(),
      loading: false
    };
  },
  methods: {
    remove(index?: number) {
      if (index === undefined) {
        this.interactions = []
        return
      }
      this.interactions.splice(index, 1)
      localStorage.setItem(this.storageKey, JSON.stringify(this.interactions))
    },
    async generate() {

      const item = []
      this.loading = true
      try {
        const response = await this.dataStore.generateResponse(this.tenantId, undefined, this.message);

        if (response) {
          item.push({
            type: 'review',
            date: new Date(),
            message: this.message
          })

          item.push({
            type: 'response',
            date: new Date(),
            message: response
          })

          this.interactions.unshift(item)
          this.message = ''

          localStorage.setItem(this.storageKey, JSON.stringify(this.interactions))
          this.dataStore.addToastMessage({
            severity: "positive",
            caption: this.$t("adHoc.success"),
            message: this.$t("app.success"),
          })
        }
      } catch (e) {
        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("adHoc.error"),
          message: this.$t("app.errorServer"),
        })
        console.error(e);
      } finally {
        this.loading = false
      }
      
    }
  },
  computed: {
    storageKey() {
      return `interactions-${this.tenantId}`
    }
  },
  watch: {
    tenantId() {
      this.interactions = []
      const interactions = localStorage.getItem(this.storageKey)
      if (interactions) {
        this.interactions = JSON.parse(interactions)
      }
    }
  },
  mounted() {
    const interactions = localStorage.getItem(this.storageKey)
    if (interactions) {
      this.interactions = JSON.parse(interactions)
    }
  }

})
</script>