<template>
<div class="filter-field text-hy-primary">
  {{ displayValue }}<q-menu anchor="center middle" self="center middle" transition-show="scale" transition-hide="scale">
    <q-list style="min-width: 100px">
      <q-item clickable v-close-popup v-for="option in options[type]" @click="internalValue = option.value;">
        <q-item-section>{{ option.label }}</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { useAuthStore }from '@/stores/auth';
import { Filter } from '@/types';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<'state' | 'tenant' | 'range' | 'platform' | 'sentiment'>,
      required: true
    },
    modelValue: {
      type: [String, Object] as PropType<string | Filter['range']>,
      required: true
    },
    allowAllTenants: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    displayValue() {
      if (typeof this.modelValue === 'object' && this.type === 'range') {
        if(this.modelValue.type === 'predefined') {
          //@ts-ignore
          return this.options[this.type].find(o => o.value === this.modelValue.value)?.label
        } else {
          if (this.$d(this.modelValue.value[0] as Date, 'tiny') == this.$d(this.modelValue.value[1] as Date, 'tiny')) {
            return this.$d(this.modelValue.value[0] as Date, 'tiny')
          } else {
            return `${this.$d(this.modelValue.value[0] as Date, 'tiny')} - ${this.$d(this.modelValue.value[1] as Date, 'tiny')}`
          }
        }
      }
      return this.options[this.type].find(o => o.value === this.modelValue)?.label
    },
    internalValue: {
      get() {
        return this.modelValue
      },
      set(value: string) {
        if (this.type === 'range') {
          this.$emit('update:modelValue', { type: 'predefined', value })
        } else {
          this.$emit('update:modelValue', value)
        }
      }
    },
    options() {
      return {
        range: [
          { label: this.$t('filter.range.options.7_days'), value: '7_days' },
          { label: this.$t('filter.range.options.14_days'), value: '14_days' },
          { label: this.$t('filter.range.options.30_days'), value: '30_days' },
          { label: this.$t('filter.range.options.90_days'), value: '90_days' },
          // { label: this.$t('filter.range.options.180_days'), value: '180_days' },
          // { label: this.$t('filter.range.options.360_days'), value: '360_days' },
        ],
        platform: [
          { label: this.$t('filter.allPlatforms'), value: '*' }
        ].concat(useAuthStore().getPlatforms().map(p => ({ label: p.name.replace('-readonly', ''), value: p.id }))),
        state: [
          { label: this.$t('filter.state.options.all'), value: '*' },
          { label: this.$t('filter.state.options.replied'), value: 'replied' },
          { label: this.$t('filter.state.options.open'), value: 'open' },
          { label: this.$t('filter.state.options.hasText'), value: 'has_text' },
          { label: this.$t('filter.state.options.noText'), value: 'no_text' },
          { label: this.$t('filter.state.options.edited'), value: 'edited' },
        ].sort((a, b) =>  a.label > b.label ? 1 : -1),
        sentiment: [
          { label: this.$t('filter.allSentiments'), value: '*' },
          { label: this.$t('filter.sentiment.options.positive'), value: 'positive' },
          { label: this.$t('filter.sentiment.options.neutral'), value: 'neutral' },
          { label: this.$t('filter.sentiment.options.negative'), value: 'negative' },
        ],
        tenant: this.allowAllTenants ? 
          [{ label: this.$t('filter.allTenants'), value: '*' }].concat(
            useAuthStore().getTenants().map(t => ({ label: t.name, value: t.id }))
          ) : 
          useAuthStore().getTenants().map(t => ({ label: t.name, value: t.id }))
      }
    }
  }
})
</script>
<style lang="sass" scoped>
@import '@/styles/variables.sass'
.filter-field
  display: inline
  cursor: pointer
  
.body--light
  .filter-field
    text-decoration: underline dotted $hy-text-default--light  
.body--dark
  .filter-field
    text-decoration: underline dotted $hy-text-default--dark

</style>