<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $tc("app.insights", 2) }}</h1>
    <div class="row q-mb-xl">
      <div class="col-12">
        <fn-filter @change="fetch($event)" />
      </div>
    </div>
    <div class="row q-mb-xl" v-if="!loading && !insights">
      <div class="col-12 text-center">
        {{ $t('app.noData') }}
      </div>
    </div>
    <div style="position: relative; min-height: 300px">
      <q-inner-loading :showing="loading" />
      <div v-if="insights">
        <div class="row q-col-gutter-xl">
          <div class="col-12 col-md-8 offset-md-2">
            <h2 class="text-center">{{ $t('insights.total') }}</h2>
            <p class="text-left">{{ insights.summary }}</p>
          </div>
          <div class="col-12 col-md-8 offset-md-2">
            <h2 class="text-center">{{ $t('insights.negative') }}</h2>
            <template v-for="(item) in insights?.negative">
              <p>
                <span class="text-hy-primary text-negative" style="font-weight: 600">{{ item.summary }}</span>: {{ item.message }}
              </p>
            </template>
          </div>
          <div class="col-12 col-md-8 offset-md-2">
            <h2 class="text-center">{{ $t('insights.positive') }}</h2>

            <template v-for="(item) in insights?.positive">
              <p>
                <span class="text-hy-primary text-positive" style="font-weight: 600">{{ item.summary }}</span>: {{ item.message }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { Filter, Insights } from '@/types';
import { defineComponent } from 'vue';
import FnFilter from '@/components/Filter/Form.vue';
import { useDataStore } from '@/stores/data';

export default defineComponent({
  components: {
    FnFilter
  },
  data() {
    return {
      dataStore: useDataStore(),
      insights: null as null | Insights,
      filter: null as null | Filter,
      loading: true
    }
  },
  methods: {
    async fetch(filter: Filter) {
      this.filter = filter
      this.loading = true
      try {
        this.insights = await this.dataStore.fetchInsights(filter, this.$i18n.locale as 'en' | 'de');
      } catch (e) {
        this.insights = null

        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("app.error"),
          message: this.$t("app.errorServer"),
        })
        console.error(e);
      } finally {
        this.loading = false
      }

    }
  },
  watch: {
    '$i18n.locale'() {
      this.fetch(this.filter as Filter)
    }
  }

})
</script>