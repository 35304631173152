<template>
  <div class="navigation">
    <router-link v-if="isAuthenticated && scopes.dashboard !== 'disabled'" to="/">
      <q-btn flat class="hy-nav">{{ $t('app.dashboard') }}</q-btn>
    </router-link>
    <router-link v-if="isAuthenticated && scopes.interactions !== 'disabled'" to="/interactions">
      <q-btn flat class="hy-nav">{{ $tc('app.interaction', 2) }}</q-btn>
    </router-link>
    <router-link v-if="isAuthenticated && scopes.insights !== 'disabled'" to="/insights">
      <q-btn flat class="hy-nav">{{ $t('app.insights') }}</q-btn>
    </router-link>
    <router-link v-if="isAuthenticated && scopes.adHoc !== 'disabled'" to="/ad-hoc">
      <q-btn flat class="hy-nav">{{ $t('adHoc.short') }}</q-btn>
    </router-link>
    <router-link v-if="isAuthenticated && scopes.settings !== 'disabled'" to="/settings">
      <q-btn flat class="hy-nav">{{ $t('app.settings') }}</q-btn>
    </router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  computed: {
    isAuthenticated() {
      return useAuthStore().isAuthenticated
    },
    scopes() {
      return useAuthStore().getScopes()
    }
  }
})
</script>