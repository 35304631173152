<template>
  <div class="platform-box">
    <h3 class="ellipsis text-center">
      {{ data.platform.charAt(0).toUpperCase() + data.platform.slice(1).replace('-readonly', '') }}
    </h3>
    <q-separator class="q-my-sm" />
    <div class="text-center content" style="transition: opacity .3s ease-in-out" :style="loading ? 'opacity: .5' : ''">
      <img :src="`/images/platform/${data.platform.replace('-readonly', '')}-color--${$q.dark.isActive ? 'dark' : 'light'}.png`" alt="" />
      {{ resolveSentiment(data.sentimentAvg as number) }}<br>
      {{ data.reviewCount }} {{ $tc('app.review', data.reviewCount) }}<br>
      <!-- {{ data.responseCount }} {{ $tc('app.response', data.responseCount) }} -->
    </div>
  </div>
</template>

<script lang="ts">
import type { DashboardPlatform } from '@/types';
import { defineComponent, PropType } from 'vue';
import HySentimentMixin from '@/mixins/Sentiment.vue';

export default defineComponent({
  mixins: [HySentimentMixin],
  props: {
    data: {
      type: Object as PropType<DashboardPlatform>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
})
</script>

<style lang="sass" scoped>
@import '@/styles/variables.sass'
.platform-box 
  cursor: pointer
  transition: background .2s ease-in-out
  border-radius: $hy-border-radius
  // background: white
  h3 
    margin: 0 

  .content
    position: relative
    img
      height: 2.5rem
      position: absolute
      left: 1rem
      top: .5rem
      // filter: grayscale(100%)
      // opacity: .5
      // transition: filter .3s ease-in-out, opacity .3s ease-in-out
  // &:hover
  //   .content img
  //     filter: grayscale(0)
  //     opacity: 1
.body--light    
  .platform-box:hover
    background: $hy-hover-background--light
.body--dark
  .platform-box:hover
      background: $hy-hover-background--dark
</style>
