<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $tc("app.interaction", 2) }}</h1>
    <div class="row q-mb-xl">
      <div class="col-12">
        <hy-filter @change="fetch($event)" />
      </div>
    </div>
    <div class="row q-mb-xl" v-if="!loading && !list.length">
      <div class="col-12 text-center">
        {{ $t('app.noData') }}
      </div>
    </div>
    <div class="row q-col-gutter-xl">
      <!-- BEGIN Menu -->
      <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-3 offset-lg-0">
        <hy-review-select :list="list" @select="setReview($event)" :selected="reviewIndex" :height="scrollHeight" :loading="loading"/>
      </div>
      <!-- END Menu -->
      <!-- BEGIN Interaction -->
      <div class="col-12 col-lg-9" v-if="!loading && list.length && list[reviewIndex]">
        <hy-review-form :review="list[reviewIndex]" :scrollHeight="scrollHeight" @update:review="list[reviewIndex] = $event"/>
      </div>
      <!-- END Interaction -->
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { Filter, Review } from '@/types';
import HyFilter from '@/components/Filter/Form.vue';

import HyReviewForm from '@/components/Interactions/Review.vue'
import HyReviewSelect from '@/components/Interactions/Select.vue'
import { useDataStore } from '@/stores/data';
import { useRootStore } from '@/stores/root';

export default defineComponent({
  components: {
    HyFilter,
    HyReviewForm,
    HyReviewSelect
  },

  data() {
    
    return {
      list: [] as Review[],
      reviewIndex: 0,
      mobileMenu: false,
      //@todo fix me for mobile horizontal :-)
      scrollHeight: 'calc(100vh - 320px)',
      dataStore: useDataStore(),
      loading: true,
    }
  },
  methods: {
    setReview(index: number) {
      this.reviewIndex = index
      useRootStore().setActiveReview(this.list[index])
    },
    async fetch(filter: Filter) {
      this.loading = true
      try {
        this.list = await this.dataStore.fetchInteractionData(filter)
        if (this.list?.length) {
          this.reviewIndex = 0
        } else {
          this.reviewIndex = 0
        }
      } catch (e) {

        this.list = []
        this.reviewIndex = 0

        this.dataStore.addToastMessage({
          severity: "negative",
          caption: this.$t("app.error"),
          message: this.$t("app.errorServer"),
        })
        console.error(e);
      } finally {
        this.loading = false
      }
    },
  }
})
</script>

<style lang="sass">
@import '@/styles/variables.sass'

</style>