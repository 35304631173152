<template>
  <section class="q-ma-xl dashboard">
    <h1 class="text-center">Styles</h1>
    <div class="row q-col-gutter-xl">
      <div class="col-12">
        <p class="text-center">
          This is a page for styles development
        </p>
      </div>

      <div class="col-12 ">
        <h2 class="text-center">Typography</h2>
        <h1>Heading 1</h1>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <h2>Heading 2</h2>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.</p>
        <h3>Heading 3</h3>
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
          clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
          consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.</p>
      </div>
      <div class="col-12">
        <h2 class="text-center">Colors</h2>
        <p class="text-center">
          <q-btn flat round dense color="info" icon="local_florist" class="q-mr-sm" />
          <q-btn flat round dense color="positive" icon="local_florist" class="q-mr-sm" />
          <q-btn flat round dense color="warning" icon="local_florist" class="q-mr-sm" />
          <q-btn flat round dense color="negative" icon="local_florist" class="q-mr-sm" />
          <q-btn round dense color="info" icon="local_florist" class="q-mr-sm" />
          <q-btn round dense color="positive" icon="local_florist" class="q-mr-sm" />
          <q-btn round dense color="warning" icon="local_florist" class="q-mr-sm" />
          <q-btn round dense color="negative" icon="local_florist" class="q-mr-sm" />
        </p>
      </div>
      <div class="col-12">
        <h2 class="text-center">Buttons</h2>
        <q-btn label="Primary" color="hy-primary" class="q-mr-sm" />
        <q-btn label="Default" color="hy-default" class="q-mr-sm" />
        <q-btn label="Secondary" color="hy-secondary" class="q-mr-sm" />
        <q-btn flat label="Primary" color="hy-primary" class="q-mr-sm" />
        <q-btn flat label="Default" color="hy-default" class="q-mr-sm" />
        <q-btn flat label="Secondary" color="hy-secondary" class="q-mr-sm" />
        <q-btn round dense color="hy-primary" icon="local_florist" class="q-mr-sm" />
        <q-btn round dense color="hy-default" icon="local_florist" class="q-mr-sm" />
        <q-btn round dense color="hy-secondary" icon="local_florist" class="q-mr-sm" />
        <q-btn flat round dense color="hy-primary" icon="local_florist" class="q-mr-sm" />
        <q-btn flat round dense color="hy-default" icon="local_florist" class="q-mr-sm" />
        <q-btn flat round dense color="hy-secondary" icon="local_florist" class="q-mr-sm" />
      </div>
      <div class="col-12">
        <h2 class="text-center">Tooltip and Toasts</h2>
        <q-btn label="Show Error" color="hy-primary" class="q-mr-sm" @click="showToast('negative')"><q-tooltip>I am
            groot!</q-tooltip></q-btn>
        <q-btn label="Show Warning" color="hy-primary" class="q-mr-sm" @click="showToast('warning')"><q-tooltip>I am
            groot!</q-tooltip></q-btn>
        <q-btn label="Show Success" color="hy-primary" class="q-mr-sm" @click="showToast('positive')"><q-tooltip>I am
            groot!</q-tooltip></q-btn>
        <q-btn label="Show Info" color="hy-primary" class="q-mr-sm" @click="showToast('info')"><q-tooltip>I am
            groot!</q-tooltip></q-btn>
      </div>
      <div class="col-12">
        <h2 class="text-center">Timeline</h2>
        <q-timeline color="hy-primary">
          <q-timeline-entry heading>
            Timeline heading
          </q-timeline-entry>
          <q-timeline-entry title="Event Title" subtitle="February 22, 1986" icon="done_all">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </div>
          </q-timeline-entry>
          <q-timeline-entry title="Event Title" subtitle="February 21, 1986" icon="delete">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </div>
          </q-timeline-entry>
          <q-timeline-entry heading>
            November, 2017
          </q-timeline-entry>
          <q-timeline-entry title="Event Title" subtitle="February 22, 1986" icon="done_all">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </div>
          </q-timeline-entry>
          <q-timeline-entry title="Event Title" subtitle="February 22, 1986" icon="done_all">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </div>
          </q-timeline-entry>
          <q-timeline-entry title="Event Title" subtitle="February 22, 1986">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </div>
          </q-timeline-entry>
          <q-timeline-entry title="Event Title" subtitle="February 22, 1986">
            <div>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </div>
          </q-timeline-entry>
        </q-timeline>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useRootStore } from '@/stores/root';
import { ToastMessage } from '@/types';

export default defineComponent({
  name: 'Styles',
  methods: {
    showToast(severity: ToastMessage["severity"]) {
      useRootStore().addToastMessage({
        caption: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.',
        message: 'I am groot!',
        severity: severity
      });
    }
  }
});
</script>