import axios, { AxiosResponse } from 'axios';
import { useAuthStore } from '@/stores/auth';

axios.defaults.baseURL = import.meta.env.VITE_BACKEND_URL;

      
axios.interceptors.request.use(async (config) => {
  
  // refresh token on expiry
  if (useAuthStore().isAboutToExpire && config.url !== '/auth/refresh' && config.url !== '/auth/logout' && config.url !== '/auth/login') {
    console.log('refreshing token for', config.url)
    await useAuthStore().refreshIdToken()
    
  }

  // add token to request header  
  if(config.url !== '/auth/refresh'  && config.url !== '/auth/login') {
    config.headers.Authorization = `Bearer ${useAuthStore().customIdToken}`  
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // check if the error is a 401 Unauthorized and if we haven't already retried
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;  // Marking that we are now retrying the request

      // sometimes, it is too late; token already expired and a request failed in the middle;
      // try to refresh token and retry the original request
      try {
        await useAuthStore().refreshIdToken();

        originalRequest.headers.Authorization = `Bearer ${useAuthStore().customIdToken}`;

        // retrying the original request with the new token
        return axios(originalRequest);
      } catch (refreshError) {
        // if token refresh fails, logout and redirect to login
        useAuthStore().logout();
        alert('Session expired. You will be redirected to the login page.');
        window.location.href = '/#/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);