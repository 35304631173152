<template>
  <q-select filled color="hy-primary" v-model="selectedReview"
    :options="internalList" emit-value
     @virtual-scroll="onScroll" class="lt-lg w-full" >
    <template v-slot:selected>
      <hy-review-item :style="`width: 100%;`" :review="selectedReview" :clickable="false" active small/>
    </template>
      <template v-slot:option="scope">
        <hy-review-item :review="scope.opt" v-bind="scope.itemProps" small/>
        </template>
  </q-select>
  <div :style="`height: ${height}; position:relative`" class="gt-md">
    <q-inner-loading :showing="loading" style="z-index:99" />

    <q-virtual-scroll :style="`max-height: ${height};`" :items="internalList" v-slot="{ item, index }"
      @virtual-scroll="onScroll">
      <hy-review-item :review="item" :active="item._id === list[reviewIndex]?._id" @setReview="reviewIndex = index" />
    </q-virtual-scroll>
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { Review } from '@/types';
import HyReviewItem from '@/components/Interactions/Item.vue'

export default defineComponent({
  components: {
    HyReviewItem,
  },
  props: {
    list: {
      type: Object as PropType<Review[]>,
      required: true
    },
    selected: {
      type: Number as PropType<number>,
      required: true
    },
    height: {
      type: String as PropType<string>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ['select'],
  data() {
    return {
      internalList: [] as Review[],
      pageSize: 30,
      pageNumber: 1,
    }
  },
  computed: {
    reviewIndex: {
      get() {
        return this.selected
      },
      set(value: number) {

        this.$emit('select', value)
      }
    },
    selectedReview: {
      get() {
        return this.list[this.selected] || null
      },
      set(value: Review) {
        this.$emit('select', this.list.indexOf(value))
      }
    },
  },
  methods: {
    onScroll(e: { index: number, from: number, to: number }) {

      const lastIndex = this.internalList.length - 1

      if (e.to === lastIndex && lastIndex < this.list.length) {
        this.internalList = this.list.slice(0, ++this.pageNumber * this.pageSize)
      }
    }
  },
  watch: {
    list: {
      handler(value: Review[]) {
        this.internalList = value.slice(0, this.pageSize)
      }
    }
  }
})
</script>