<template>
  <span v-if="platform === 'facebook'" class="ellipsis">
    {{ rating.value === 1 ? $t('interactions.recommended') : $t('interactions.notRecommended') }}
  </span>
  <span v-else-if="platform === 'google'" style="white-space: nowrap;">
    <q-icon v-for="i in rating.value" name="star" :key="i"/>
    <q-icon v-for="i in 5 - rating.value" name="star_outline" :key="i" />
  </span>
  <span v-else-if="platform === 'booking-readonly'" style="white-space: nowrap;">
    <div class="booking-rating" :class="active ? 'bg-hy-primary' : 'bg-hy-default'">
      <q-tooltip :delay="500" anchor="top middle" self="center middle">{{ $tc('interactions.rating.booking', rating.value) }}</q-tooltip>
      {{ rating.value }}
    </div>
  </span>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { ReviewRating } from '@/types';

export default defineComponent({
  props: {
    rating: {
      type: Object as PropType<ReviewRating>,
      required: true
    },
    platform: {
      type: String as PropType<string>,
      required: true
    },
    active: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  }
})
</script>
<style lang="sass">
.booking-rating
  border-radius: 5px
  border-bottom-left-radius: 0
  font-size: 14px
  width: 28px
  text-align: center
  transition: background-color .2s ease-in-out
  display: inline-block
</style>