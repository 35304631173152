<template>
  <section class="q-mx-xl q-my-md">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <h1 class="text-center ellipsis">{{$t('login.title')}}</h1>
        <p class="text-center q-mb-xl">{{$t('login.welcome')}}</p>
        <form @submit.prevent="submitForm">
          <q-input filled :label="$t('login.email')" type="text" id="email" v-model="authEmail" class="q-mb-lg" :disable="authStore.isLoading" color="hy-primary" />
          <q-input filled :label="$t('login.password')" type="password" id="password" v-model="authPassword" class="q-mb-xl" :disable="authStore.isLoading" color="hy-primary" />
          <div class="text-center">
            <q-btn type="submit" color="hy-primary" :disable="authStore.isLoading || !authEmail || !authPassword">
              <q-tooltip :delay="500" anchor="top middle" self="bottom middle" v-if ="!authEmail || !authPassword" >{{$t('login.disabled')}}</q-tooltip>
              <q-spinner v-if="authStore.isLoading" size="1em"/>
              <span v-else>
              {{$t('login.doLogin')}}
              </span>
            </q-btn>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRootStore } from '@/stores/root';

export default defineComponent({
  data() {
    return {
      authStore: useAuthStore(),
      authEmail: '',
      authPassword: ''
    }
  },
  methods: {
    async submitForm() {
      try {
        await this.authStore.login(this.authEmail, this.authPassword)

        this.$router.push('/')

      } catch (error: any) {
        console.error(error.message)
        useRootStore().addToastMessage({
          severity: "negative",
          caption: this.$t("login.errorCaption"),
          message: this.$t("login.errorTitle"),
        })
      }
    }
  },
})
</script>