
import { createApp } from 'vue'
import App from './App.vue'
import { Quasar, Notify, Dialog } from 'quasar'
import { createPinia } from 'pinia'
import { setBrowserPreferences } from './plugins/browser.ts'

// Import styles
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import 'highcharts/css/highcharts.css'
import '@/styles/highcharts.sass'
import '@/styles/main.sass'

// Setup axios
import './plugins/axios.ts'

// Icon set defaults
import iconSet from 'quasar/icon-set/material-icons'
iconSet.table.arrowUp = 'expand_less'


// Setup i18n
import i18nInstance from './plugins/i18n.ts'

// // Import router
import { getRouter } from './plugins/router'

// Set browser preferences
setBrowserPreferences()

const app = createApp(App)


app.use(Quasar, { plugins: { Notify, Dialog }, iconSet: iconSet })
  .use(getRouter())
  .use(createPinia())
  .use(i18nInstance)
  .mount('#app')
