<template>
  <filter-field type="tenant" v-model="tenant" :allow-all-tenants="false" @update:model-value="emitChange()" />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import FilterField from './Field.vue';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  components: {
    FilterField
  },
  emits: ['change'],
  data() {
    return {
      tenant: this.getInitialValue()
    } 
  },
  methods: {
    emitChange(addToStore = true) {

      if (addToStore) {
        sessionStorage.setItem('hyoban.tenant-selected', this.tenant)
      }

      this.$emit('change', this.tenant)
    },
    isMultiTenant() {
      return useAuthStore().getTenants().length > 1
    },
    getInitialValue() {
      // get value from session storage
      const value = sessionStorage.getItem(`hyoban.tenant-selected`)

      if (value) {
        return value
      }
      // default values
      return useAuthStore().getTenants()[0].id
    }
  },
  mounted() {
    this.emitChange(false)
  }
})
</script>