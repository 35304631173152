<template>
  <h2 class="ellipsis text-center">{{ $t('dashboard.development') }}</h2>
  <div style="height: 300px; position: relative;">
    <div ref="chart" style="height: 300px;"></div>
    <div class="tooltip wrap justify-center items-center row" :class="ttData ? 'visible' : ''" ref="tooltip" >
      <div class="inner text-center" v-if="ttData">
        <span class="text-hy-primary">{{ ttData?.date }}</span>: 
        <span class="text-positive">{{ ttData.positive }}</span> {{ $tc(`dashboard.pie.positive`, ttData.positive) }}, 
        <span class="text-neutral">{{ ttData.neutral }}</span> {{ $tc(`dashboard.pie.neutral`, ttData.neutral) }}, 
        <span class="text-negative">{{ ttData.negative }}</span> {{ $tc(`dashboard.pie.negative`, ttData.negative) }}
      </div>
        <!-- <div class="value">{{ ttData?.count }}</div>
        <div class="type text-center">{{ ttData ? $tc(`dashboard.pie.${ttData.sentiment}`, ttData.count) : '' }}</div> -->
    </div>
    <q-inner-loading :showing="loading" />
    <div v-if="!loading && (!data || !data.length)" class="justify-center items-center chart-empty">{{ $t('app.noData') }}</div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

import Highcharts from "highcharts/es-modules/masters/highcharts.src.js";
import { DashboardDevelopment } from "@/types";
import { format } from "date-fns";
import HySentimentMixin from '@/mixins/Sentiment.vue';

export default defineComponent({
  mixins: [HySentimentMixin],
  props: {
    data: {
      type: [Object, null] as PropType<DashboardDevelopment[] | null>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ['drilldown'],
  data() {
    return {
      chart: null as null | Highcharts.Chart,
      ttData: null as DashboardDevelopment | null
    };
  },
  methods: {
    render() {

      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      if (!this.data || !this.data.length) {
        return;
      }

      const categories = this.data.map(d => this.$d(new Date(d.date), 'tiny'));

      let me = this

      this.chart = Highcharts.chart({
        chart: {
          renderTo: this.$refs.chart as HTMLElement,
          styledMode: true
        },
        accessibility: { enabled: false },
        legend: { enabled: false },
        credits: { enabled: false },
        title: { text: undefined },
        xAxis: {
          // tickAmount: 10,
          labels: {
            rotation: 0,
            formatter: function (item: any) {
              return categories[item.pos];
            }
          },
        },
        yAxis: [{
          tickAmount: 3,
          alternateGridColor: true,
          showFirstLabel: false,
          title: {
            text: undefined
          },
        }
        ] as unknown as Highcharts.YAxisOptions[],
        series: [
          {
            name: 'positive',
            type: 'column',
            className: 'hy-series-secondary',
            data: this.data.map(d => { return {y: d.positive, className: 'hy-positive'}}),
          },
          {
            name: 'neutral',
            type: 'column',
            className: 'hy-series-secondary',
            data: this.data.map(d => { return {y: d.neutral, className: 'hy-neutral'}}),
          },
          {
            name: 'negative',
            type: 'column',
            className: 'hy-series-secondary',
            data: this.data.map(d => { return {y: d.negative, className: 'hy-negative'}}),
          }
        ] as Highcharts.SeriesOptionsType[],
        tooltip: {
          shared: true,
          formatter() {
            
            //@ts-ignore
            me.ttData = { date: categories[this.x], positive: this.points[0].y, neutral: this.points[1].y, negative: this.points[2].y};
            return false;
          }
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            stacking: 'normal',
            point: {
              events: {
                mouseOut: function () {
                  me.ttData = null
                },
                click: function () {
                  me.$emit(
                    'drilldown', 
                    //@ts-ignore
                    format(new Date(me.data[this.category as number].date), 'yyyy-MM-dd')
                  )
                }
              }
            }
          }
        }

      })
    }
  },
  watch: {
    data() {
      this.render();
    },
    '$i18n.locale': function () {
      this.render();
    },
    '$q.dark.isActive': function () {
      this.render();
    }
  },
  mounted() {
    this.render();
  }
});
</script>
<style lang="sass" scoped>
.chart-empty
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  color: var(--q-color-grey-5)
  text-align: center
  z-index: 1
  pointer-events: none
  width: 100%
.tooltip
  position: absolute
  width: 100%
  height: 20px
  top: 0
  z-index: 99
  opacity: 0
  transition: opacity .2s ease-in-out
  &.visible
    opacity: 1  
  .inner
    background-color: rgba(255, 255, 255, .75)

body.body--dark
  .tooltip
    .inner
      background-color: rgba(18, 18, 18, .75)
 </style>