<template>
  <div class="translation-container" :class="{'open': open, 'loading': loading}">
    <q-separator v-if="open" class=q-my-md />
    <q-inner-loading :showing="loading" color="hy-primary"/>
    <div v-html="text" class="q-mt-md translation-text"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String as PropType<string>,
      required: true
    },
    open: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
})

</script>
<style lang="sass" scoped>  
.translation-container
  position: relative
  .translation-text
    font-size: 1rem
    opacity: .8
    transform-origin: top
    transform: scaleY(0)
    transition: transform .2s ease-in-out
  &.loading
    .translation-text
      transform: scaleY(0)
  &.open    
    min-height: 50px
    .translation-text
      transform: scaleY(1)
  &.open.loading  
    .translation-text
      transform: scaleY(0)
</style>