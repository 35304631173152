import { defineStore } from 'pinia'
import { useRootStore } from './root'
import { DashboardResponse, Filter, Insights, Review, Tenant, ToastMessage } from '@/types'
import { format, subDays } from 'date-fns'
import axios from 'axios'

const resolveFilter = (filter: Filter): any => {

  const resolved: any = {}

  if (filter.range.type === 'custom') {
    resolved.from = format(filter.range.value[0], 'yyyy-MM-dd')
    resolved.to = format(filter.range.value[1], 'yyyy-MM-dd')
  } else {
    //@ts-ignore
    resolved.from = format(subDays(new Date(), (filter.range.value).split('_')[0]), 'yyyy-MM-dd')
    resolved.to = format(new Date(), 'yyyy-MM-dd')
  }

  resolved.tenant = filter.tenant,
  resolved.state = filter.state,
  resolved.platform = filter.platform,
  resolved.sentiment = filter.sentiment

  return resolved
}

export const useDataStore = defineStore('data', {

  state() {
    return {
    }
  },

  actions: {
    isLoading(key: string | null = null): boolean {
      return useRootStore().isLoading(key)
    },
    addToastMessage(value: ToastMessage) {
      useRootStore().addToastMessage(value)
    },
    fetchDashboardData: async (filter: Filter): Promise<DashboardResponse> => {
      const res = await axios.get('/dashboard', {
        params: resolveFilter(filter)
      })

      return res.data
    },
    fetchInteractionData: async (filter: Filter): Promise<Review[]> => {
      const res = await axios.get('/interactions', {
        params: resolveFilter(filter)
      })

      return res.data
    },
    fetchInsights: async (filter: Filter, locale: 'en' | 'de'): Promise<Insights> => {
      const res = await axios.get('/insights', {
        params: Object.assign({}, resolveFilter(filter), { locale })
      })
      
      return res.data
    },

    syncSingle: async (reviewId: string): Promise<{ review: Review, hasChanged: boolean }> => {
      const res = await axios.get(`/interaction/sync-single?reviewId=${reviewId}`)
      return res.data as { review: Review, hasChanged: boolean }
    },

    generateResponse: async (tenantId: string, reviewId?: string, reviewText?: string): Promise<string> => {
      const res = await axios.post('/genai/gen-response', {
        tenantId, 
        reviewId, 
        reviewText
      })

      return res.data.message
    },

    saveResponse: async (reviewId: string, newDraftText: string): Promise<Review> => {
      const res = await axios.post('/interaction/update-draft', {
        reviewId,
        newDraftText
      })

      return res.data.review
    },

    publishResponse: async (reviewId: string, responseText: string): Promise<Review> => {
      const res = await axios.post('/interaction/publish-response', {
        reviewId,
        responseText: responseText
      })

      return res.data.review
    },

    deleteResponse: async (reviewId: string, externalId: string): Promise<Review> => {
      const res = await axios.delete(`/interaction/delete-response?reviewId=${reviewId}&responseExternalId=${externalId}`)

      return res.data.review
    },

    fetchSettings: async (tenantId: string): Promise<Tenant> => {
      const res = await axios.get('/tenant', {
        params: { tenantId }
      })

      return res.data
    },
    
    generateSettings: async (tenantId: string, url: string): Promise<{name: string, sectors: string[], tonality: string[], reviewToneInstructions: string}> => {
      const res = await axios.post('/genai/gen-settings', {
        tenantId, url
      })

      return res.data.settings
    },

    saveSettings: async (settings: Tenant): Promise<Tenant> => {
      const res = await axios.put('/tenant', settings)
      
      return res.data
    },

    submitFeedback: async (feedback: string, smartlookSessionId: string, activeReview: Review|null): Promise<boolean> => {
      try {
        const res = await axios.post('/feedback', {
          feedback, 
          smartlookSessionId, 
          review: activeReview,
          url: window.location.href
        })

        if (res.status === 200) {
          return true
        } else {
          console.error('feedback error', res)
          return false
        }
      }
      catch (e) {
        return false
        console.error(e)
      }
    },

    
  }

})