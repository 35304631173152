import {createI18n} from 'vue-i18n'
import messagesEn from '../locales/en.json'
import messagesDe from '../locales/de.json'
// import messagesFr from '../locales/fr.json'

// Type-define 'en-US' as the master schema for the resource
type MessageSchema = typeof messagesEn


const datetimeFormats = {
  'en': {
    tiny: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }
  },
  'de': {
    tiny: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
  },
  // 'fr': {
  //   tiny: {
  //     year: '2-digit',
  //     month: 'numeric',
  //     day: 'numeric'
  //   },
  //   short: {
  //     year: 'numeric',
  //     month: 'short',
  //     day: 'numeric'
  //   },
  //   long: {
  //     year: 'numeric',
  //     month: 'short',
  //     day: 'numeric',
  //     weekday: 'short',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: false
  //   }
  // }
}

const numberFormats = {
  'en': {
    currency: {
      style: 'currency', currency: 'EUR', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    natural: {
      style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  'de': {
    currency: {
      style: 'currency', currency: 'EUR', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    natural: {
      style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  },
  // 'fr': {
  //   currency: {
  //     style: 'currency', currency: 'EUR', notation: 'standard'
  //   },
  //   decimal: {
  //     style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
  //   },
  //   natural: {
  //     style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
  //   },
  //   percent: {
  //     style: 'percent', useGrouping: false
  //   }
  // }
}
const instance = createI18n<[MessageSchema], 'en' | 'de'>({
  locale: 'en',
  warnHtmlInMessage: "off",

  //@ts-ignore
  datetimeFormats,
  //@ts-ignore
  numberFormats,
  messages: {
    'en': messagesEn,
    //@ts-ignore
    'de': messagesDe,
    //@ts-ignore
    // 'fr': messagesFr,
  }
})

export default instance;

export const i18n = instance.global;