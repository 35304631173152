export const setBrowserPreferences = () => {
  
  if (!window.localStorage.getItem('hyoban.language')) {
    window.localStorage.setItem('hyoban.language', navigator.language.split('-')[0] === 'de' ? 'de' : 'en')
  }


  if (!window.localStorage.getItem('hyoban.dark')) {
    window.localStorage.setItem(
      'hyoban.dark', 
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'on' : 'off'
    )
  }
}