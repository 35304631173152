<template>
  <section class="q-mx-xl q-my-md">
    <h1 class="text-center ellipsis">{{ $t("access.notBookedTitle") }}</h1>
    <p class="text-center">
      {{ $t("access.notBookedMessage") }}
    </p>
    <p class="text-center">
      {{ $t(`access.${$route.params.scope}`) }}
    </p>
  </section>
</template>