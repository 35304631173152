
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent ({
  methods: {  
    resolveSentiment(sentiment: number): string {
      if (sentiment > .75) {
        return `${this.$t('dashboard.sentiment.veryPositive')} (+${this.$n(sentiment, 'decimal')})`
      } else if (sentiment > .5) {
        return `${this.$t('dashboard.sentiment.positive')} (+${this.$n(sentiment, 'decimal')})`
      } else if (sentiment > .25) {
        return `${this.$t('dashboard.sentiment.slightlyPositive')} (+${this.$n(sentiment, 'decimal')})`
      } else if (sentiment < -.75) {
        return `${this.$t('dashboard.sentiment.veryNegative')} (${this.$n(sentiment, 'decimal')})`
      } else if (sentiment < -.5) {
        return `${this.$t('dashboard.sentiment.negative')} (${this.$n(sentiment, 'decimal')})`
      } else if (sentiment < -.25) {
        return `${this.$t('dashboard.sentiment.slightlyNegative')} (${this.$n(sentiment, 'decimal')})`
      } else {
        return `${this.$t('dashboard.sentiment.neutral')} (${sentiment > 0 ? '+' : ''}${this.$n(sentiment, 'decimal')})`
      }
    }
  }
})
</script>