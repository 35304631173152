<template>
  <h2 class="ellipsis text-center">{{ $t('dashboard.distribution') }}</h2>
  <div style="height: 300px; position: relative;">
    <div ref="chart" style="height: 300px;"></div>
    <div class="tooltip wrap justify-center items-center column" :class="ttData ? 'visible' : ''" ref="tooltip">
      <div class="value">{{ ttData?.reviewCount }}</div>
      <div class="type text-center">{{ ttData ? $tc(`dashboard.pie.${ttData.sentiment}`, ttData.reviewCount) : '' }}</div>
    </div>
    <q-inner-loading :showing="loading" />
  </div>
</template>
<script lang="ts">
import { DashboardDistribution } from "@/types";
import { PropType, defineComponent } from "vue";
import Highcharts from "highcharts/es-modules/masters/highcharts.src.js";

export default defineComponent({
  props: {
    data: {
      type: [Object, null] as PropType<DashboardDistribution[] | null>,
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  emits: ['drilldown'],
  data() {
    return {
      chart: null as null | Highcharts.Chart,
      ttData: null as null | DashboardDistribution,
      ttAllData: null as null | DashboardDistribution,
    };
  },
  methods: {
    render() {
      
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      if (!this.data || !this.data.length) {
        return;
      }

      let me = this;

      this.chart = Highcharts.chart({
        chart: {
          renderTo: this.$refs.chart as HTMLElement,
          styledMode: true
        },
        accessibility: { enabled: false },
        legend: { enabled: false },
        credits: { enabled: false },
        title: { text: undefined },
        plotOptions: {
          pie: {
            innerSize: '70%',
            borderWidth: 10,
            events: {
              mouseOut: function () {
                me.ttData = me.ttAllData
              }
            }

          },
          series: {
            dataLabels: [{ enabled: false }]
          },

        },
        series: [
          {
            name: 'Percentage',
            type: 'pie',
            className: 'hy-series-secondary',
            data: this.data.map(d => ({ name: d.sentiment, y: d.reviewCount, className: `hy-${d.sentiment}` }))
          }
        ],
        tooltip: {
          formatter() {
            me.ttData = { sentiment: this.key as string, reviewCount: this.y as number };
            return false;
          }
        }
      })
    }
  },
  watch: {
    data() {

      this.ttAllData = this.data ? this.data.reduce((acc, cur) => {
        return { sentiment: 'all', reviewCount: acc.reviewCount + cur.reviewCount }
      }, { sentiment: 'all', reviewCount: 0 }) : null;

      this.ttData = this.ttAllData;
      this.render();
    },
    '$i18n.locale': function () {
      this.render();
    },
    '$q.dark.isActive': function () {
      this.render();
    }
  },
  mounted() {
    this.render();
  }
})
</script>
<style lang="sass" scoped>
.tooltip
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  z-index: -1
  opacity: 0
  transition: opacity .2s ease-in-out
  &.visible
    opacity: 1
  div:first-child
    font-size: 2rem
  div:last-child
    max-width: 120px
    line-height: 1.5rem
    padding-bottom: 1rem
    
    
</style>